<template>
  <v-container fluid>
    <v-layout column fill-height wrap>
      <v-container
        style="width: 90%; max-width: 450px; max-height=90vh;"
        class="mx-auto"
      >
        <v-row>
          <v-flex xs12>
            <v-img
              class="mx-auto"
              src="@/assets/img/logo.gif"
              width="100%"
              max-width="200px"
            ></v-img>
          </v-flex>
        </v-row>
        <div v-if="!isWithdrawl">
          <v-row>
            <p
              class="font-weight-bold mx-auto pt-5 pb-0 mb-0"
              style="font-size:18px"
            >
              {{ $t('title.contractor.noticeOfContractCompletion') }}
            </p>
            <p
              v-if="isSkipped"
              class="pt-0 mx-auto"
              style="font-size:16px"
              align="center"
            >
              {{ this.subTitle }}
            </p>
          </v-row>
          <template v-if="!isSkipped">
            <v-row>
              <p>{{ $t('description.procedureComplete.explanation1') }}</p>
            </v-row>
            <v-row>
              <p>{{ $t('description.procedureComplete.explanation2') }}</p>
            </v-row>
          </template>
          <template>
            <v-row>
              <p class="pb-0 mb-0">
                {{
                  $t('description.procedureComplete.policyNumber', {
                    policyNumber: policyNumber,
                  })
                }}
              </p>
            </v-row>
            <div class="pb-4">
              <v-row>
                <p class="pb-0 mb-0">
                  {{ $t('description.procedureComplete.customerName') }}
                </p>
              </v-row>
              <v-row class="py-0 my-0">
                <p class="py-0 my-0">
                  {{ customerName }}
                </p>
              </v-row>
            </div>
          </template>
          <template v-if="!isSkipped">
            <v-row>
              <p>{{ $t('description.procedureComplete.explanation3') }}</p>
            </v-row>
          </template>
          <v-row>
            <p>{{ $t('description.procedureComplete.explanation4') }}</p>
          </v-row>
          <template>
            <v-row>
              <p>{{ $t('description.procedureComplete.explanation5-1') }}</p>
            </v-row>
            <v-row>
              <p>{{ $t('description.procedureComplete.explanation5-2') }}</p>
            </v-row>
          </template>
          <v-row>
            <p>
              {{ $t('description.procedureComplete.explanation6') }}
            </p>
          </v-row>
          <v-row>
            <p>{{ $t('description.procedureComplete.explanation7') }}</p>
          </v-row>
          <v-row>
            <p class="pt-2">
              {{ $t('description.procedureComplete.contact') }}
            </p>
          </v-row>
        </div>
        <!-- 取り下げの場合 -->
        <div v-if="isWithdrawl">
          <v-row>
            <p class="font-weight-bold mx-auto pt-5" style="font-size:18px">
              {{ $t('title.contractor.noticeOfCancel') }}
            </p>
          </v-row>
          <template v-if="isSkipped">
            <v-row>
              <p>
                {{
                  $t('description.procedureComplete.policyNumber', {
                    policyNumber: policyNumber,
                  })
                }}
              </p>
            </v-row>
            <div class="pb-4">
              <v-row>
                <p class="pb-0 mb-0">
                  {{ $t('description.procedureComplete.customerName') }}
                </p>
              </v-row>
              <v-row class="py-0 my-0">
                <p class="py-0 my-0">
                  {{ customerName }}
                </p>
              </v-row>
            </div>
          </template>
          <v-row v-if="isWithdrawl">
            <p class="pt-2">
              {{ $t('description.procedureComplete.canceled') }}
            </p>
            <p class="pt-2">
              {{ $t('description.procedureComplete.unconcludedContact') }}
            </p>
          </v-row>
        </div>
        <v-row>
          <p class="font-weight-bold pt-5" style="font-size:18px">
            {{ $t('label.agencyInfo') }}
          </p>
        </v-row>
        <v-row v-for="agencyRecord in agencyRecords" :key="agencyRecord.key">
          <v-col class="ma-0 pa-0">
            <p class="font-weight-bold mt-1">
              {{ $t(`header.agencyInfoTable.${agencyRecord.key}`) }}
            </p>
          </v-col>
          <v-col class="ma-0 pa-0">
            <p class="mt-1">{{ agencyRecord.value }}</p>
          </v-col>
        </v-row>
        <div v-if="!isWithdrawl">
          <v-row>
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              width="70vw"
              min-width="250px"
              max-width="350px"
              color="next"
              style="font-size:15px"
              dark
              @click="onClickDownloadApplicationFormButton"
              rounded
              >{{ $t('button.downloadDocument') }}</v-btn
            >
          </v-row>
          <v-row class="pt-3">
            <a class="mx-auto" :href="lineRef">
              <img width="100%" src="@/assets/img/bnr_300_250.jpg" />
            </a>
          </v-row>
          <SelectDownloadDocumentDialog
            :maxWidth="320"
            :onClickDownloadApplicationFormButton="downloadApplicationForm"
            :onClickDownloadStatementOfImportantMattersButton="
              downloadStateOfImportantMatters
            "
            :onClickNegativeButton="onClickDownloadDialogNegativeButton"
            :showDialog="showDialog"
          />
        </div>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import SelectDownloadDocumentDialog from '@/components/organisms/contractor/SelectDownloadDocumentDialog.vue';
import {
  getAgencyInfo,
  getCompletedApplicationForm,
  getExplanationsForImportantMatter,
  getStatus,
  getContractDate,
  getBaseInfo,
} from '@/apis/contractor/contracts';
import { ContractStatus } from '@/lib/const';
import { mapGetters } from 'vuex';
import { LineID } from '@/lib/const';

export default {
  components: {
    SelectDownloadDocumentDialog,
  },
  data: () => ({
    isWithdrawl: false,
    agencyRecords: [
      {
        key: 'name',
        value: '',
      },
      {
        key: 'staffName',
        value: '',
      },
      {
        key: 'phoneNumber',
        value: '',
      },
    ],
    lineRef: `https://line.me/R/ti/p/@${LineID}`,
    subTitle: '',
    showDialog: false,
    policyNumber: '',
    customerName: '',

    // スキップされたかどうか
    isSkipped: false,
  }),
  methods: {
    onClickDownloadApplicationFormButton() {
      this.showDialog = true;
    },
    async downloadApplicationForm() {
      const applicationForms = await getCompletedApplicationForm();
      if (applicationForms instanceof Error) {
        this.$router.replace('/Error');
        return;
      }
      this.download(applicationForms, this.$t('file.applicationForm'));
    },
    async downloadStateOfImportantMatters() {
      const explanationsForImportantMatter = await getExplanationsForImportantMatter();
      if (explanationsForImportantMatter instanceof Error) {
        this.$router.replace('/Error');
        return;
      }
      this.download(
        explanationsForImportantMatter,
        this.$t('file.explanationsForImportantMatter')
      );
    },
    download(data, fileName) {
      if (data) {
        let link = document.createElement('a');

        const userAgent =
          navigator.userAgent || navigator.vendor || window.opera;
        let isIos = false;
        // iOS機種であるかを判別
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          isIos = true;
        }
        if (isIos) {
          link.href = window.URL.createObjectURL(
            new Blob([data], { type: 'application/octet-stream' })
          );
        } else {
          link.href = window.URL.createObjectURL(
            new Blob([data], { type: 'application/pdf' })
          );
        }

        link.download = fileName;
        link.click();
      }
    },
    onClickDownloadDialogNegativeButton() {
      this.showDialog = false;
    },
    ...mapGetters('contract', ['isProcedureSkipped']),
  },
  async mounted() {
    // 手続きがスキップ指定である(再ログイン時など)かどうかをセットする
    this.$set(this, 'isSkipped', this.isProcedureSkipped());

    const contractStatus = await getStatus();

    this.isWithdrawl = contractStatus.status === ContractStatus.Withdrawal;

    const response = await getAgencyInfo();
    if (response instanceof Error) {
      this.$router.replace('/Error');
      return;
    }
    if (response) {
      this.$set(this, 'agencyRecords', [
        {
          key: 'name',
          value: response.name,
        },
        {
          key: 'staffName',
          value: response.staffName,
        },
        {
          key: 'phoneNumber',
          value: response.phoneNumber,
        },
      ]);
    }
    const contractDateResponse = await getContractDate();
    if (contractDateResponse instanceof Error) {
      this.$router.replace('/Error');
      return;
    }
    if (contractDateResponse) {
      const contractDate = moment(contractDateResponse.contractDate).format(
        'YYYY年M月D日'
      );
      switch (contractStatus.status) {
        case ContractStatus.Concluded:
          this.subTitle = this.$t('description.procedureComplete.subTitle', {
            date: contractDate,
          });
          break;
        case ContractStatus.Accounted:
          this.subTitle = this.$t(
            'description.procedureComplete.subTitleAccounted',
            {
              date: contractDate,
            }
          );
          break;
        case ContractStatus.AccountedWithDocRequired:
          this.subTitle = this.$t(
            'description.procedureComplete.subTitleAccountedWithDocRequired',
            {
              date: contractDate,
            }
          );
          break;
        case ContractStatus.AccountingError:
          this.subTitle = this.$t(
            'description.procedureComplete.subTitleAccountingError',
            {
              date: contractDate,
            }
          );
          break;
        case ContractStatus.UnAccounted:
          this.subTitle = this.$t(
            'description.procedureComplete.subTitleUnAccounted',
            {
              date: contractDate,
            }
          );
          break;
      }
    }
    const baseInfoResponse = await getBaseInfo();
    if (baseInfoResponse instanceof Error) {
      this.$router.replace('/Error');
      return;
    }
    if (baseInfoResponse) {
      this.policyNumber = baseInfoResponse.policyNumber;
      this.customerName = this.$t(
          'description.procedureComplete.titleOfHonor',
          { name: baseInfoResponse.contractUser.fullName })
    }
  },
};
</script>
